import React from 'react';
import { Link } from 'react-router-dom';
const MainSection = () => {
  return (
    <section className="conteudo-principal">
      <div className="conteudo-principal-escrito">
        <h1 className="conteudo-principal-escrito-titulo">Locação Tirasenha</h1>
        <h2  className="conteudo-principal-escrito-subtitulo">
        Confie na Tirasenha para <b>organizar</b> sua empresa como ninguém faz. 
        </h2>
        <Link to="/Rent"><button className="conteudo-principal-escrito-botao">Verificar disponibilidade</button></Link>
      </div>
    </section>
  );
};

export default MainSection;
