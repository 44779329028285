import React from 'react';
import '../styles/carousel.css';

const Carousel = () => {

  return (
    <section id="slider">
      <div id="carouselExampleDark" className="carousel carousel-dark slide carouselMain"  data-bs-ride="carousel">
        
        {/* Indicadores do carousel */}
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>

        </div>

        {/* Imagens e legendas do carousel */}
        <div className="carousel-inner">

          <div className="carousel-item active" data-bs-interval="2500">
            <img
              id='imagemMain'
              src="https://locacao.tirasenha.com/img/pedestal_inox_prata-cd-cza.webp"
              className="d-block w-100"
              alt="Pedestal de inox com corda cinza"
            />
          </div>

          <div className="carousel-item" data-bs-interval="2500"> 
            <img
              id='imagemMain'
              src="https://locacao.tirasenha.com/img/pedestal_aco_preto-cd_mr.webp"
              className="d-block w-100"
              alt="Pedestal de aço na cor preta com corda marrom"
            />
          </div>
          <div className="carousel-item" data-bs-interval="2500"> 
            <img
              id='imagemMain'
              src="https://locacao.tirasenha.com/img/pedestal_inox_prata-fita-pt.webp"
              className="d-block w-100"
              alt="Pedestal de inox com fita na cor preta"
            />
          </div>
          <div className="carousel-item" data-bs-interval="2500"> 
            <img
              id='imagemMain'
              src="https://locacao.tirasenha.com/img/pedestal_inox_prata-fita-az.webp"
              className="d-block w-100"
              alt="Pedestal de inox com fita na cor azul"
            />
          </div>
        </div>
                  
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
       
        
      </div>
    </section>
  );
};

export default Carousel;
