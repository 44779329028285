
import React from 'react';

const ReasonsList = () => {
  return (
    <section className="conteudo-secundario">
      <h3 className="conteudo-secundario-titulo">
        Por que alugar com a Tirasenha
      </h3>
      <p className="conteudo-secundario-paragrafo">
      <strong> 1. Experiência de mercado:</strong> Com mais de 13 anos de atuação no setor, possuímos expertise consolidada, garantindo um serviço de alta qualidade e total confiabilidade para todos os clientes.
      </p>
      <p className="conteudo-secundario-paragrafo">
      <strong> 2. Disponibilidade garantida:</strong> Nossos produtos estão sempre prontos para locação. Reserve agora e tenha certeza de que o material estará disponível no momento em que você desejar.
      </p>
      <p className="conteudo-secundario-paragrafo">
        3. <strong>Especialistas no produto:</strong> Somos especialistas no que fazemos. Conhecemos a fundo cada equipamento que oferecemos, garantindo suporte técnico eficiente e soluções rápidas.
      </p>
      <p className="conteudo-secundario-paragrafo">
        4. <strong>Planejamento simplificado:</strong> Agende seu evento com apenas 5 dias de antecedência e tenha a tranquilidade de que tudo estará pronto no prazo, sem complicações ou atrasos.
      </p>
      <p className="conteudo-secundario-paragrafo">
        5. <strong>Logística completa:</strong> Oferecemos a conveniência de entregar e retirar os equipamentos diretamente no seu evento, deixando você focado no que realmente importa.
      </p>
    </section>
  );
};

export default ReasonsList;
