// Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="rodape">
      <img
        className="rodape-imagem"
        src="https://locacao.tirasenha.com/img/logo_ts.webp"
        alt="Logo Tirasenha"
      />
    </footer>
  );
};

export default Footer;
