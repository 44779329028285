import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/cardCarousel.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const CardCarousel = (props) => {
  const {title, description, linkText, images, lotes, onloteChange, href} = props;
  const [selectedlote, setSelectedlote] = useState(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    const popoverTrigger = popoverRef.current;
    if (popoverTrigger && !selectedlote) {
      new window.bootstrap.Popover(popoverTrigger, {
        trigger: 'hover focus',
        content: 'Escolha uma opção antes de continuar',
      });
    } else if (popoverTrigger && selectedlote) {
      const popoverInstance = window.bootstrap.Popover.getInstance(popoverTrigger);
      if (popoverInstance) {
        popoverInstance.dispose(); // Remove o popover se um lote for selecionado
      }
    }
  }, [selectedlote]);

  return (
    <div id='card' className="col-md-4 mb-4 p-3 d-flex justify-content-center align-items-center bd-highlight">
      <div className="card d-flex justify-content-center align-items-center">
        
        {/* Carousel dentro do Card */}
        <div id={`carousel-${title.replaceAll(" ","_")}`} 
        className="carousel carousel-dark slide" 
        data-bs-ride="carousel" 
        data-bs-interval="2500">
          <div className="carousel-indicators">
            {images.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target={`#carousel-${title.replaceAll(" ","_")}`}
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>

          {/* Imagens e legendas do carousel */}
          <div className="carousel-inner">
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img src={image.src} className="d-block w-100" alt={image.alt} />
              </div>
            ))}
          </div>
              <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${title.replaceAll(" ","_")}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${title.replaceAll(" ","_")}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
        </div>

        {/* Conteúdo do Card abaixo do Carousel */}
        <div className="card-body">
          <h5 className="card-title text-center">{title}</h5>
          <p className='text-center' dangerouslySetInnerHTML={{ __html: description }} />
          <hr/>
          {/* Lotes (map) */}
          <div className="form-check">
            {lotes && lotes.map((lote, index) => (
              <div key={index} className="form-check ">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`${title.replaceAll(" ","_")}_lote`}
                  id={`${title.replaceAll(" ","_")}_lote_${index}`}
                  value={lote}
                  checked={selectedlote === lote}
                  onChange={() => {
                    setSelectedlote(lote);
                    onloteChange(lote);
                  }}
                />
              <label className="form-check-label" htmlFor={`${title.replaceAll(" ","_")}_lote_${index}`}>
                   {`${
                    lote==="1"?"Lote 1 - 2-5 pedestais"
                    :lote==="2"?"Lote 2 - 2-10 pedestais"
                    :lote==="3"?"Lote 3 - 2-15 pedestais"
                    :lote==="P-fita"?"Personalização da fita"
                    :lote==="P-pedestal"?"Personalização do pesdestal"
                    :lote==="P-pedestal+fita"?"Personalização fita+pedestal"
                    :"lote não identificado"
                    }`}
                </label>
              </div>
            ))}
          </div>

          {/* Link com Popover */}
          <div ref={popoverRef} className="d-inline-block" tabIndex="0" data-bs-toggle="popover" data-bs-trigger="hover focus">
            <Link to={href} className={`btn btn-primary focus-ring ${!selectedlote ? 'disabled' : ''} ` }>
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCarousel;
