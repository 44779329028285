import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';

const Header = () => {
  return (
    <header className="cabecalho">
      <Link to="/" className="cabecalho-menu-item">
        <img
          className="cabecalho-imagem"
          src="https://locacao.tirasenha.com/img/logo_ts.webp"
          alt="Logo Tirasenha"
        />
      </Link>
    </header>
  );
};

export default Header;
