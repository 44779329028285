import React from "react";
import Carousel from "../components/Carousel"
import Header from "../components/Header"
import Footer from "../components/Footer"
import MainSection from "../components/MainSection"
import ReasonsList from "../components/ReasonsList"
import "../styles/mainSectionCarousel.css"
function Main(){
return(<>
    <Header/>
    <main className="conteudo">
    <div className="main-carousel-container">
      <MainSection />
      <Carousel />
    </div>
      <ReasonsList/>
      <Footer />
    </main>
    </>
)
}

export default Main;